<template>
    <div class="mb-4">
        <div class="mb-4">
            <h4 class="font-weight-600">Modules</h4>
        </div>
        <div class="row pb-4">
            <template v-if="loading">
                <div v-for="(mod, index) in 3" :key="index" class="col-sm-6 col-xl-4">
                    <div class="module-card mx-auto">
                        <div class="img mw-100 mb-3">
                            <b-skeleton width="100%" height="9rem"></b-skeleton>
                        </div>
                        <ul class="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center contains mb-3">
                            <li v-for="(tag, index) in 3" :key="index">
                                <div class="contains-item d-flex align-items-center">
                                    <b-skeleton width="3rem" height="1.2rem"></b-skeleton>
                                </div>
                            </li>
                        </ul>
                        <div class="progress mb-3">
                            <b-skeleton width="45%" height="100%"></b-skeleton>
                        </div>
                        <h5 class="title text-capitalize">
                            <b-skeleton width="8rem" height="1.1rem"></b-skeleton>
                        </h5>
                        <p class="sepciality text-capitalize">
                            <b-skeleton width="6rem" height="0.8rem"></b-skeleton>
                        </p>
                        <div class="d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex align-items-center">
                                <b-skeleton class="img-circle" width="45px" height="45px"></b-skeleton>
                                <small class="owner-name mx-2 text-capitalize">
                                    <b-skeleton width="5rem" height="0.7rem"></b-skeleton>
                                </small>
                            </div>
                            <span class="price">
                                <b-skeleton width="4rem" height="0.7rem"></b-skeleton>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="!loading && modules.length">
                <div v-for="(module, index) in modules" :key="index" class="col-sm-6 col-xl-4">
                    <ModuleCard 
                    :cardData="module" 
                    @storedAsFavorite="getRecently()"/>
                </div>
            </template>
            <div v-else 
            class="col-md-7 col-lg-6 mx-auto text-center my-5">
                <p class="no-modules mx-auto py-2">{{ $t('noResults') }}</p>
            </div>

            <!-- Pagination -->
            <div
                v-if="lastPageID - 1"
                class="
                    col-12
                    d-flex
                    justify-content-center
                    py-4
                    mb-3
                    overflow-hidden
                ">
                <nav aria-label="Page navigation">
                    <ul class="pagination">
                        <li v-if="!isFirstPage" class="page-item">
                            <button 
                            @click="fetchPage('prev')"
                            class="page-link">
                            {{ $t('prev') }}
                            </button>
                        </li>
                        <li v-for="(page, index) in pagesLinks" :key="index"
                        class="page-item">
                            <button
                                @click="fetchPage(page.id)"
                                :class="[currentPage == page.id ? 'active' : '']"
                                :disabled="currentPage == page.id"
                                class="page-link">{{ page.id }}</button
                            >
                        </li>
                        <li v-if="!isLastPage" class="page-item">
                            <button 
                            @click="fetchPage('next')"
                            class="page-link">
                            {{ $t('next') }}
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleCard from "@/components/ModuleCard";
import { moduleTask } from "@/API/my-account";
import pagination from "@/mixins/pagination";

export default {
    name: "Modules",
    mixins: [pagination],
    components: {
        ModuleCard,
    },
    data() {
        return {
            loading: true,
            pagesLinks: [],
            currentPage: 1,
            isFirstPage: true,
            isLastPage: false,
            lastPageID: 1,
            modules: []
        };
    },
    methods: {
        getModuleTask() {
            moduleTask(this.currentPage).then(res => {
                this.loading = false;
                
                if (res.status == 200) {
                        const data = res.data?.data;
                        // console.log(data);

                        if (data?.module) this.modules = data.module;

                        if (data?.paginate) {
                            this.pagesLinks = this.pagination(data.paginate, this.currentPage).pagesLinks;
                            this.isFirstPage = this.pagination(data.paginate).isFirstPage;
                            this.isLastPage = this.pagination(data.paginate).isLastPage;
                            this.lastPageID = this.pagination(data.paginate).lastPageID;
                        }
                    }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }
            });
        },
        fetchPage(id) {
            if (typeof(id) == 'number') {
                this.currentPage = id;
                this.getPartnerModules();
            } 

            if (id == 'prev' && !this.isFirstPage) {
                this.currentPage = this.currentPage - 1;
                this.getPartnerModules();
            }

            if (id == 'next' && !this.isLastPage) {
                this.currentPage = this.currentPage + 1;
                this.getPartnerModules();
            }
        },
    },
    mounted() {
        this.getModuleTask();
    }
};
</script>
